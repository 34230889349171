import './App.css';
import Typical from 'react-typical'

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <p>
                    <Typical
                        loop={Infinity}
                        wrapper="b"
                        steps={
                            [
                                'compiling...', 1500,
                                'loading...', 1500,
                                'arranging...', 1500,
                                'composing...', 1500,
                                'organizing...', 1500,
                                'collecting...', 1500,
                                'sorting...', 1500,
                                'gathering...',1500,
                                'synonym overflow!', 5000,
                                'restarting script...',3000
                            ]
                        }
                    />
                </p>
            </header>
        </div>
    );
}

export default App;
